
import { Options, Vue } from "vue-class-component";
import Section from "../lib/section";
import SingleLine from "./SingleLine.vue";
import PropertiesComponent from "./PropertiesComponent.vue";
import LiabilitiesComponent from "./LiabilitiesComponent.vue";
import SingleTextComponent from "./SingleTextComponent.vue";
import { Store } from "@/lib/store";
import Question from "@/lib/question";

@Options({
  expose:  ['validate'],
  props: {
    section: {
      type: Section,
      required: true,
    },
  },
  computed: {
    getFilteredQuestions() {
      return this.section.getFilteredQuestions(Store.getMortgageType());
    },
    sectionName() {
      return this.section.getName(Store.state);
    },
    subtitle() {
      return this.section.getSubtitle(Store.getApplicantCount());
    }
   
  },
  methods: {
    getQuestionLabel(question:Question) {
      return question.getQuestionLabel(Store.getMortgageType());
    },
     getClass() {
      return '';
      //return question?.extras?.hideMobile || false ? "d-none d-xl-block" : '';
    },
    validate() {
      return true;
    }
  },
  components: {
    SingleLine,
    PropertiesComponent,
    LiabilitiesComponent,
    SingleTextComponent,
  },
})
export default class SectionComponent extends Vue {}
