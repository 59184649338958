
    import { defineComponent } from "vue";
    export default defineComponent({
        name: 'ModalComponent',
        props: {
          hideFooter: Boolean,
          cancelable: Boolean
        },
        methods: {
          cancel() {
            if(this.cancelable) {
              this.$emit('close');
            }
          }
        }
    });
