
import { defineComponent } from "@vue/runtime-core";
import Question from "../lib/question";
import { Store } from "../lib/store";
import Property from '../lib/property';

export default defineComponent({
  name: "PropertiesComponent",
  props: {
    question: {
      type: Question,
      required: true,
    },
  },
  methods: {
      
      addRow() {
          let newData = this.answers;
          if(Array.isArray(newData)) {
            newData.push(new Property());
          }
          Store.setAnswer(this.question.key,newData, 0);
      },
      deleteRow(index:number) {
          let newData = this.answers as [];
          newData.splice(index, 1);
          Store.setAnswer(this.question.key,newData, 0);
      }
  },
  computed: {
    answers():Property[] {
      const answer = Store.getAnswer(this.question.key, 0, [new Property()]);
      if(Array.isArray(answer)) {
        return answer;
      }
      return [new Property()];
    }
  }
});
