import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionComponent = _resolveComponent("SectionComponent")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.page.sections, (section, index) => {
    return (_openBlock(), _createElementBlock("section", {
      key: index,
      class: "section"
    }, [
      (section.component == undefined)
        ? (_openBlock(), _createBlock(_component_SectionComponent, {
            key: 0,
            section: section,
            ref: _ctx.setRef
          }, null, 8, ["section"]))
        : (_openBlock(), _createBlock(_resolveDynamicComponent(section.component), {
            key: 1,
            section: section,
            ref: _ctx.setRef
          }, null, 8, ["section"]))
    ]))
  }), 128))
}