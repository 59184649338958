import axios from "axios"
import { AdminStore } from "./adminStore";
import { IState } from "./store";

interface IFormResponse {
  data: string;
  email: string
}

interface IJWTResponse {
  user: any,
  token: any
}

export default {
  async storeForm(data: IState, email: string) {
    const res = await axios.post<string>("/api", { data: JSON.stringify(data), email: email });
    return res.data;
  },
  async updateForm(code: number, data: IState) {
    const res = await axios.put("/api/" + code, { data: JSON.stringify(data) });
    return res.data;
  },
  async finaliseForm(data: IState) {
    const res = await axios.post<string>("/api/final", { data: JSON.stringify(data) });
    return res.data;
  },
  async getForm(code: number) {
    const res = await axios.get<IFormResponse>("/api/" + code);
    return JSON.parse(res.data.data);
  },

  async getFinal() {
    const res = await axios.get<any>("/api/admin/final", {
      headers: {
        'Authorization': `Bearer ${AdminStore.getToken()}`
      }
    });
    console.log(res.data);
    return res.data;
  },

  async getFinalId(id: string) {
    const res = await axios.get<any>("/api/admin/final/" + id,  {
      headers: {
        'Authorization': `Bearer ${AdminStore.getToken()}`
      }
    });
    console.log(res.data);
    return res.data;
  },

  async login(username: string, password: string): Promise<boolean> {
    const data = await axios.request<any>({
      method: 'post',
      url: '/auth/login',
      data: {
        username: username,
        password: password
      }
    });
    if (data.status != 200) {
      return false;
    }
    console.log(data);
    AdminStore.setToken(data.data.token);
    AdminStore.setUser(data.data.user);
    return data.status == 200;
  }
}