
import { Store } from "../lib/store";
import { defineComponent } from "vue";
import ModalComponent from "./ModalComponent.vue";
export default defineComponent({
  name: "IntroComponent",
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      pageIndex: 0,
    };
  },
  methods: {
    setApplicationCount(count: number) {
      Store.setApplicantCount(count);
      this.pageIndex++;
    },
    setMortgageType(type: string) {
      Store.setMortgageType(type);
      this.$emit("close");
    },
  },
  components: {
    ModalComponent,
  },
});
