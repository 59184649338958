
import { defineComponent } from "vue";
import Question from "../lib/question";
import { Store } from "../lib/store";

export default defineComponent({
  name: "SingleTextComponent",
  emits: ["update:modelValue"],
  props: {
    question: {
      type: Question,
      required: true,
    },
    textarea: {
      type: Boolean
    },
    modelValue: {
      required: true,
      type: String
    },
    
  },
  methods: {

    handleBlur() {
      Store.blur();
    },
    getAnswer() {
      
      return Store.getAnswer(this.question.key, 0);
    },
    setAnswer(val: string) {
      Store.setAnswer(this.question.key, val, 0);
      this.$emit('update:modelValue', val);
    }
  },
});
