import { reactive } from "vue";

export const AdminStore = {

    state: reactive({
        user:null,
        token:null
    }),

    setUser(user:any) {
        this.state.user = user;
    },

    setToken(token:any) {
        this.state.token = token;
    },

    getUser() {
        return this.state.user;
    },

    getToken() {
        return this.state.token;
    }

    

}