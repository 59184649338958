
import { defineComponent } from "vue";

export default defineComponent({
  name: "FinishComponent",
  components: {},
  methods: {
    finish() {
      window.location.href = "https://liquidexpatmortgages.com/";
    },
  },
});
