export default class Property {
    address: string = '';
    lender: string = '';
    currentOwners:string = '';
    value: string = '';
    datePurchased: string = '';
    mortgageLeft: string = '';
    monthlyPayment:string = '';
    rentReceived:string = '';

}