
import { defineComponent } from "vue";
import Page from '../lib/page';
import SectionComponent from './SectionComponent.vue';
import FCAComponent from "./FCAComponent.vue";

export default defineComponent({
  name: "PageComponent",
  expose: ['validate'],
  components: {
      SectionComponent,
      FCAComponent
  },
  props: {
    page: {
      type: Page,
      required: true,
    },
  },
  data() {
    return {
      sectionRefs: [] as Array<any>
    }
  },
  methods: {
    setRef(element:any) {
      if(element) {
        this.sectionRefs.push(element);
      }
    },
    validate() {
      let validate = true;
      
      this.sectionRefs.forEach(function(s) {
        console.log(s);
          if(!s.validate()) {
            validate = false;
          }
        }
      );
      return validate;
    }
  },
  beforeUpdate() {
    this.sectionRefs = []
  },
});
