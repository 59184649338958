import 'mutationobserver-shim'
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue';
import FactFind from './pages/FactFind.vue'
import Admin from './pages/Admin.vue'
import ViewFactFind from './pages/ViewFactFind.vue';
import Login from './pages/Login.vue';
import { AdminStore } from './lib/adminStore';



// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
    { path: '/', component: FactFind },
    {
        path: '/admin', component: Admin, meta: { authorize: [] }
    },
    {
        path: '/admin/:id', component: ViewFactFind, meta: { authorize: [] }
    },
    { path: '/login', component: Login }
]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

router.beforeEach((to: any, from, next) => {
    const { authorize } = to.meta;
    const loggedIn = AdminStore.getToken() != null;

    if (authorize) {
        if (!loggedIn) {
            // not logged in so redirect to login page with the return url
            return next({ path: '/login', query: { returnUrl: to.path } });
        }
    }
    next();
})

createApp(App).use(router).mount('#app');
