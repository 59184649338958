import Question from './question';
import { IState } from './store';
import  {Component} from 'vue';
type FunctionTitle = ((state:IState) => string) | string;

export default class Section {
    questions: Question[];
    private name: FunctionTitle;
    private subtitle:string|string[];
    singleAnswer: boolean;
    component: string | undefined;

    constructor(name: FunctionTitle, questions: Question[], singleAswer:boolean = false, subtitle?:string|string[], component?:string) {
        this.name = name;
        this.questions = questions;
        this.singleAnswer = singleAswer;
        this.subtitle = subtitle || "";
        this.component = component;
    }

    getSubtitle(count: Number) {
        if(!Array.isArray(this.subtitle)) {
            return this.subtitle;
        }
        if(count  == 1 || this.subtitle.length == 1) {
            return this.subtitle[0];
        }
        return this.subtitle[1];
    }

    getName(state: IState):string {
        console.log(state);
        if (typeof this.name === 'string') {
            return this.name;
        }
        return this.name(state);
    }

    getFilteredQuestions(mortgageType:string):Question[] {
        // eslint-disable-next-line prefer-const
        let filtered:Question[] = [];

        this.questions.forEach(function(q) {
            
            if(q.filter.mortgageType !== undefined) {
                if(q.filter.mortgageType == mortgageType) {
                    filtered.push(q);
                }
            } else {
                filtered.push(q);
            }
        });

        return filtered;
    }
    
}

