import Section from "./section";


export default class Page {
    name: string;
    sections: Section[];
    
    constructor(name: string, sections: Section[]) {
        this.name = name;
        this.sections = sections;
    }

}