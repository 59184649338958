import { reactive } from "vue"
import Network from './network';

export class Answer {
    applicantIndex: number = 0;
    key: string = '';
    answer: string | any[] = '';
}


export interface IState {
    answers: Answer[];
    applicantCount: number;
    pageIndex: number;
    applicationType: string;
    mortgageType: string;
    code: number;

}

export const Store = {
    debug: true,

    state: reactive<IState>({
        answers: [],
        applicantCount: 1,
        pageIndex: 0,
        applicationType:'single',
        mortgageType: 'purchase',
        code: -1
    }),

    blurHandler: () => {},
    applicantTwoHandler: () => {},

    setAnswer(key: string, data: string | any[], index: number = 0) {
        console.log(key,data,index);
        const newData: Answer = { applicantIndex: index, key: key, answer: data };
        const existingIndex = this.state.answers.findIndex(a => a.key == key && a.applicantIndex == index);
        if(existingIndex > -1) {
            this.state.answers[existingIndex] = newData;
        } else {
            this.state.answers.push(newData);
        }
    },

    getAnswer(key: string, index: number = 0, defaultVal:string|any[] = ''): string | any[] {
        let found = false;
        this.state.answers.forEach(a => {
            if (a.key == key && a.applicantIndex == index) {
                defaultVal = a.answer;
                found = true;
            }
        });
        if(!found && defaultVal != '' ) {
            this.setAnswer(key, defaultVal);
        }
        return defaultVal;

    },

    setEmail(email:string) {
        return this.setAnswer("EMAIL", email);
    },

    getEmail():string {
        const answer = this.getAnswer("EMAIL");
        if(typeof answer != 'string') {
            return "";
        }
        return answer;
    },

    setApplicantCount(count: number) {
        this.state.applicantCount = count;
    },

    getApplicantCount(): number { return this.state.applicantCount; },

    nextPage() {
        this.state.pageIndex++;
    },

    prevPage() {
        this.state.pageIndex--;
    },

    getPageIndex():number {return this.state.pageIndex;},

    setPageIndex(index:number) {
        this.state.pageIndex = index;
    },

    getMortgageType():string {
        return this.state.mortgageType;
    },

    setMortgageType(value: string) {
        this.state.mortgageType = value;
    },

    setCode(code:number) {
        this.state.code = code;
    },

    getCode(): number {
        return this.state.code;
    },

    getStore(): IState {
        return this.state;
    },

    setStore(data:IState) {
        Object.assign(this.state, data);
    },

    //simple event system
    addBlurHandler(handler:() => {}) {
        this.blurHandler =  handler;
    },

    blur() {
        this.blurHandler();
    },

    addApplicantTwoFocusHandler(handler:() => {}) {
        this.applicantTwoHandler =  handler;
    },

    applicantTwoFocus() {
        this.applicantTwoHandler();
    }

    


}