import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f1dea40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row py-4" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "d-flex mb-3 align-items-center" }
const _hoisted_5 = ["srcdoc"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.data.name) + " - " + _toDisplayString(_ctx.data.email), 1),
            _createElementVNode("button", {
              class: "btn btn-danger ms-auto",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back()))
            }, "Back")
          ]),
          _createElementVNode("iframe", {
            srcdoc: _ctx.data.html
          }, null, 8, _hoisted_5)
        ])
      ])
    ])
  ]))
}