
import { defineComponent } from "@vue/runtime-core";
import ModalComponent from "./ModalComponent.vue";
import Network from "../lib/network";
import { Store } from "../lib/store";

export default defineComponent({
  name: "SaveComponent",
  components: {
    ModalComponent,
  },
  data() {
    return {
      emailTo: this.email || "",
      saved: false
    };
  },

  methods: {
    //TODO: error
    save() {
      if (this.emailTo.trim() == "") {
        return;
      }
      try {
      if (Store.getCode() == -1) {
        Network.storeForm(Store.getStore(), this.emailTo)
          .then((code) => {
            Store.setCode(parseInt(code));
            this.saved = true;
          })
          .catch(() => alert("There was a problem saving your form, if this keeps happening please contact support"));
      } else {
        Network.updateForm(Store.getCode(), Store.getStore())
          .then(() => this.saved = true)
          .catch(() => alert("There was a problem saving your form, if this keeps happening please contact support"));
      }}
      catch {
        alert("There was a problem saving your form, if this keeps happening please contact support");
      }
    },
  },
  props: {
    showModal: Boolean,
    email: String,
  },
});
