
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import type {YesNoUndefined} from "../lib/yesNoUndefined";

export default defineComponent({
  name: "YesNoComponent",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<YesNoUndefined>
    },
    name: {
      required: true,
      type: String
    }
  },
  methods: {
    emitEvent(value:Boolean) {
      console.log(value);
      this.$emit('update:modelValue', value)
    }
  }
});
