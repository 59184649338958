
import { defineComponent } from "@vue/runtime-core";
import Question from "../lib/question";
import { Store } from "../lib/store";

export default defineComponent({
  name: "SingleLine",

  props: {
    question: {
      type: Question,
      required: true,
    },
    textarea: Boolean,
    singleline: Boolean,
    singleAnswer: Boolean,
  },
  data() {
    return { linked: false };
  },
  methods: {
    toggleLink() {
      this.linked = !this.linked;
    },
    handleBlur() {
      Store.blur();
    },
    applicantTwoFocus(count: number) {
      if (count < 2 || !this.showLink) {
        return;
      }
      Store.applicantTwoFocus();
    },
    getAnswer(index: number) {
      if (this.linked && index > 1) {
        index = 1;
      }
      return Store.getAnswer(this.question.key, index - 1);
    },
    setAnswer(index: number, val: string) {
      if (index > 1) {
        this.linked = false;
      }
      Store.setAnswer(this.question.key, val, index - 1);
      if (this.linked) {
        Store.setAnswer(this.question.key, val, 1);
      }
    },
    applicantPlaceholder(index: number) {
      if (this.applicantCount == 1) {
        return;
      }
      if (index == 1) {
        return "applicant one";
      }
      return "applicant two";
    },
  },
  computed: {
    applicantCount() {
      if (this.singleAnswer) {
        return 1;
      }
      const count = Store.getApplicantCount();
      return count;
    },
    showLink() {
      return this.question.extras.showLink || false;
    },
    className() {
      return "single-line-" + Store.getApplicantCount();
    },
  },
});
