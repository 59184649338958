
import network from "@/lib/network";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  async setup() {
    const route = useRoute();
    const id = route.params.id;
    var data = await network.getFinalId(id.toString());
    return {
      data: data,
    };
  },
  methods: {
    back() {
      this.$router.back();
    }
  }
});
