import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "sectionSubTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.sectionName != '')
      ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.sectionName), 1))
      : _createCommentVNode("", true),
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.subtitle), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFilteredQuestions, (item) => {
      return (_openBlock(), _createElementBlock("section", {
        key: item.key,
        class: _normalizeClass(["question", _ctx.getClass(item)])
      }, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.getQuestionLabel(item)), 1),
        (_openBlock(), _createBlock(_resolveDynamicComponent(item.componentType), _mergeProps({
          question: item,
          singleAnswer: _ctx.section.singleAnswer
        }, item.extras), null, 16, ["question", "singleAnswer"]))
      ], 2))
    }), 128))
  ], 64))
}