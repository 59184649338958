
import { defineComponent } from "vue";
import ModalComponent from "./ModalComponent.vue";
import { Store } from '../lib/store';

export default defineComponent({
  name: "DisclaimerComponent",
  components: {
    ModalComponent,
  },
  computed: {
      joint() {
          return Store.getApplicantCount() > 1;
      }
  },
  emits: ["close", "confirm"],
});
