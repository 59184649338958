
import { defineComponent } from "vue";
import network from "@/lib/network";
export default defineComponent({
  setup() {},
  data: () => {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {
      console.log(this.email, this.password);
      var result = await network.login(this.email, this.password);
      if (result) {
        this.$router.push("/admin");
      }
    },
  },
});
