import Page from "./page";
import Question from "./question";
import Section from "./section"
import {IState, Store } from "./store";

const Section1 = new Section(
    (state:IState) => state.applicantCount == 1 ? 'Sole Applicant Details' :  'Joint Application Details', 
    [
    new Question('FULL_NAME', 'Your Full Name as Appears on Passport (Any given 1st & 2nd Name & Surname)', {}, 'SingleLine', { singleline: true }),
    new Question('MAIDEN_NAME', 'Have you had any Previous Names/Maiden Name & Date Changed Name', {}, 'SingleLine', { singleline: true }),
    new Question('DOB', 'Date of Birth', {}, 'SingleLine', { singleline: true }),
    new Question('MARITAL_STATUS', 'Marital Status', {}, 'SingleLine', { singleline: true, showLink: true}),
    new Question('DEPENDANTS', 'Number of Dependants (please confirm their name and ages)'),
    new Question('BIRTH_TOWN', 'Country & Town of Birth'),
    new Question('BRITISH_PASSPORT', 'British Passport Holder (Yes/No/BNO Passport Holder)'),
    new Question('RETIREMENT_AGE', 'Expected Retirement Age'),
    new Question('TELEPHONE_NUMBERS', 'Telephone Numbers (please confirm the international dialling code)', {}, 'SingleLine', {showLink:true}),
    new Question('EMAIL', 'Email Address', {}, 'SingleLine', { singleline: true, showLink: true })
]);


const Section2 = new Section('Your Address Details', [
    new Question('RESIDENTIAL_ADDRESS', 'Current Residential Address Including Postcode (in the country you currently live and work)', {}, 'SingleLine', {showLink: true}),
    new Question('DATE_MOVED', 'Date Moved to This Address', {}, 'SingleLine', {showLink: true}),
    new Question('PREV_ADDRESS', 'Previous Address if Applicable (if current address less than 3 years please provide full 3 years address history)', {}, 'SingleLine', {showLink: true}),
    new Question('DATES_RESIDED', 'Dates Resided At This Address', {}, 'SingleLine', {showLink: true}),
    new Question('OVERSEAS_COSTS', 'Current rent or mortgage costs (in the country you currently live and work) if Nil, why?', {}, 'SingleLine', {showLink: true}),
    new Question('UK_BANK_ACCOUNT', 'Do You Still Hold a UK Bank Account? (address account registered at)', {}, 'SingleLine', {showLink: true}),
    new Question('DATE_RESIDED_UK', 'Date Last Resided in UK', {}, 'SingleLine', {showLink: true}),
    new Question('PREV_UK_ADDRESS', 'Previous UK Address', {}, 'SingleLine', {showLink: true}),
    new Question('FIRSTTIME_BUYER', 'Are You a First time Buyer in The UK', { mortgageType: 're-mortgage' }, 'SingleLine', {showLink: true})
]);

const Section3 = new Section('Your Employment Details', [
    new Question('OCCUPATION', 'Occupation/ Job Title'),
    new Question('EMPLOYER', 'Employer'),
    new Question('EMPLOYEMNT_STATUS', 'Employment Status (employed, self employed or contractor)'),
    new Question('EMPLOYERS_ADDRESS', 'Employers Address'),
    new Question('DATE_EMPLOYED', 'Date Employment Commenced (if less than 3 years please also declare full 3 year history)'),
    new Question('TIME_IN_EMPLOYMENT', 'Time in Continuous Employment'),
    new Question('GROSS_SALARY', 'Gross Annual Salary in The Currency You Are Paid In'),
    new Question('ADDITIONAL_INCOME', ' Additional Income e.g. Bonus (please confirm whether this bonus is guaranteed)'),
    new Question('MONTHLY_INCOME', 'Net Monthly Income (in the currency you are paid in)'),
    new Question('PROPERTIES_OWNED', { purchase: 'All Properties Owned (Worldwide)', reMortgage: 'All Properties Owned (Worldwide) - Including Property To Be Re-Mortgaged' }, {}, 'PropertiesComponent',
        {
            cols: [
                'Full Address',
                'Lender and Original Amount Borrowed',
                'Name of Current Owners',
                'Current Value',
                'Date Purchased',
                'Mortgage Balance and Term Left',
                'Monthly Payment',
                'Rent Received'
            ],
            hideMobile: true
        }),
    new Question('OTHER_LIABILITIES', 'Other Liabilities', {}, 'LiabilitiesComponent',
        {
            cols: [
                'Type of Commitment (e.g. Credit Card/Loan etc.)',
                'Whose Debt Is This',
                'Lender',
                'Balance Outstanding (GBP)',
                'Credit Limit (If applicable)',
                'Monthly Payment',
                'Remaining Term or Date',
                'Purpose of Loan'
            ],
            hideMobile: true
        }),
]);

const Section4 = new Section(
    'Monthly Budget Planner:', [
    new Question('FOOD_DRINK', 'Food, Drink and Clothing'),
    new Question('UTILITIES', 'Utility Bills and Council Tax'),
    new Question('TRANSPORT', 'Transport, Entertainment, Recreation & Holidays'),
    new Question('INSURANCE', 'Insurance Premiums'),
    new Question('SCHOOL_FEES', 'School Fees'),
    new Question('MAINTENANCE', 'Maintenance Payments'),
    new Question('EXPENDITURE', 'Any other Regular Expenditure')

], true, ['(Please Complete in GBP £ and Not The Currency You Are Paid In)', '(Please Complete in GBP £ and Not The Currency You Are Paid In. To Include Applicant One and Two)']);

const Section5 = new Section((state:IState) => state.mortgageType == 'purchase' ? 'For Your Purchase' : 'For Your Re-Mortgage', [
    new Question('DEPOSIT_SOURCE', 'Source of Deposit (Please confirm if any of your funds are being gifted from a family member)', { mortgageType: 'purchase' }, 'SingleTextComponent'),
    new Question('PROPERTY_ADDRESS', { purchase: 'Full Address of Property to be Purchased', reMortgage: 'Full Address of Property to be Re-Mortgaged' }, 'SingleTextComponent')
], true);



const Section7 = new Section('Property Details', [
    new Question('PURCHASE_PRICE', { purchase: 'Purchase Price', reMortgage: 'Property Value' }),
    new Question('PROPERTY_TYPE', 'Property Type e.g Flat, House etc..'),
    new Question('MORTGAGE_BALANCE', 'Existing Mortgage Balance', { mortgageType: 're-mortgage' }),
    new Question('BEDROOMS', 'Number of Bedrooms'),
    new Question('RENTAL_INCOME', 'Rental Income (If Buy-to-Let)'),
    new Question('STOREYS', 'Number of Storeys in The Block'),
    new Question('TERM_OF_LEASE', 'Remaining Term of Lease'),
    new Question('GROUND_RENT', 'Amount of Ground Rent'),
    new Question('SERVICE_CHARGE', 'Service Charges'),
    new Question('EPC', 'Property EPC Rating (Energy Performance Rating)'),
    new Question('CAR_PARKING', 'If Purchasing a Flat, Does The Price Include a Car Parking Space', { mortgageType: 'purchase' }),
    new Question('RESIDING', 'Will Anyone over the Age of 17 be Residing in The Property Not Party to The Mortgage - If so provide Name, Relationship and Date of Birth.'),
    new Question('PURCHASE_COMPLETION', 'Date Purchased', { mortgageType: 're-mortgage' }),
    
    new Question('NEW_BUILD_DATE', 'If New Build, Please Confirm Estimated Completion Date',  { mortgageType: 'purchase' })
], true, '(Please give approximate figures if unsure)');

const Section8 = new Section('Loan Details', [
    new Question('MORTGAGE_AMOUNT', 'Mortgage Amount Required'),
    new Question('TERM_REQUIRED', 'Term Required (years)'),
    new Question('CAPITAL_RAISING', 'Reason For Any Capital Raising', { mortgageType: 're-mortgage' }),
    new Question('REPAYMENT_METHOD', 'Repayment Method (i.e. interest only or capital repayment)'),
    new Question('REPAYMENT_VEHCILE', 'If Interest Only, Please, Confirm The Intended Repayment Vehicle (i.e. sale of property, savings, overpayments etc.)'),
    new Question('MORTGAGE_OPTION', 'Mortgage Option (i.e. option 1, 2, 3 etc. from original email from your mortgage consultant)'),
    new Question('ADD_FEE_MORTGAGE', 'Are You Seeking to Add The Bank Arrangement Fee to The Mortgage? Please Note that by adding the Fee to the Mortgage Will Incur Additional Interest to be Charged.')
], true);

const Section9 = new Section('Health & Lifestyle', [
    new Question('SMOKER', 'Have You Smoked or Used any Nicotine Products in the Last 12 Months?'),
    new Question('MEDICAL_CONDITIONS', 'Do You Have any Pre-existing Medical Conditions or Have You Suffered any Serious Illness Within the Last 5 Years?'),
    new Question('HAZARDOUS_ACTIVITIES', 'Do You Take Part in any Hazardous Leisure Activities? If so, Please Advise What You Take Part in.'),
]);

const Section10 = new Section('In accordance with FCA guidelines, if you are having problems, have difficult circumstances or special requirements, we would like to ensure we can provide appropriate support and accommodations.', [
    new Question('FCA_Q1', 'Are there any specific accommodations or assistance you would like us to consider? For example, if you require larger print documents or communication in alternative formats, please let us know.'),
    new Question('FCA_Q2', 'If Yes, please give details'),
    new Question('FCA_Q3', 'Additionally, please feel free to disclose any financial worries, such as concerns about job stability or managing debt, health issues that may affect your ability to meet mortgage obligations, or difficult life events, such as a recent divorce or bereavement, that may impact your mortgage application or require additional support. Your well-being and financial stability are important to us, and we are here to assist you through any challenges you may be facing.'),
    new Question('FCA_Q4', 'If Yes, please give details')
], false, undefined, "FCAComponent");


const Page1 = new Page('About You', [Section1, Section2]);
const Page2 = new Page('Employment', [Section3, Section4]);
const Page3 = new Page('The Property', [Section5, Section7, Section8, Section9]);
const Page4 = new Page('FCA Guidelines', [Section10])

export const Form = [Page1, Page2, Page3, Page4]


