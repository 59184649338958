import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.textarea)
    ? (_openBlock(), _createElementBlock("input", {
        key: 0,
        value: _ctx.getAnswer(),
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setAnswer($event.target.value))),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)))
      }, null, 40, _hoisted_1))
    : (_openBlock(), _createElementBlock("textarea", {
        key: 1,
        value: _ctx.getAnswer(),
        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setAnswer($event.target.value))),
        onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)))
      }, null, 40, _hoisted_2))
}