
import network from "@/lib/network";
import { ref, reactive } from "vue";
import { NDataTable } from "naive-ui";
import { useRouter } from "vue-router";
export default {
  components: {
    NDataTable,
  },
  async setup() {
    var data = ref(null);

    const router = useRouter();
    data.value = await network.getFinal();
    const paginationReactive = reactive({
      page: 1,
      pageSize: 10,
      showSizePicker: true,
      pageSizes: [3, 5, 7],
      onChange: (page: number) => {
        paginationReactive.page = page;
      },
      onUpdatePageSize: (pageSize: number) => {
        paginationReactive.pageSize = pageSize;
        paginationReactive.page = 1;
      },
    });

    const emailColumn = {
      title: "Email",
      key: "email",
      sortOrder: false,
      sorter: "default",
    };
    const nameColumn = {
      title: "Name",
      key: "name",
      sortOrder: false,
      sorter: "default",
    };
    const createdColumn = {
      title: "Created",
      key: "created",
      sortOrder: "descend",
      sorter: "default",
      render(row: any) {
        console.log(row);
        return row.created;
      },
    };
    const columns = [emailColumn, nameColumn, createdColumn];
    const columnsRef = ref(columns);
    return {
      data,
      columns: columnsRef,
      pagination: paginationReactive,
      handleSorterChange(sorter: any) {
        columnsRef.value.forEach((column) => {
          /** column.sortOrder !== undefined means it is uncontrolled */
          if (column.sortOrder === undefined) return;
          if (!sorter) {
            column.sortOrder = false;
            return;
          }
          if (column.key === sorter.columnKey) column.sortOrder = sorter.order;
          else column.sortOrder = false;
        });
      },
      rowProps: (row: any) => {
        return {
          style: "cursor: pointer;",
          onClick: () => {
            console.log(router);
            router.push(`/admin/${row.id}`);
          },
        };
      },
    };
  },
};
