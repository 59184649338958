
import Section from "@/lib/section";
import { Store } from "@/lib/store";
import { defineComponent } from "@vue/runtime-core";
import YesNoComponent from "./YesNoComponent.vue";
import SingleTextComponent from "./SingleTextComponent.vue";
import {YesNoUndefined} from "../lib/yesNoUndefined";

export default defineComponent({
  name: "FCAComponent",
  components: { YesNoComponent, SingleTextComponent },
  data() {
    return {
      question1: undefined as YesNoUndefined,
      question2: undefined as YesNoUndefined,
      question1Detail: '' as String,
      question2Detail: '' as String,
      validated: false as Boolean
    };
  },
  props: {
    section: {
      type: Section,
      required: true,
    },
  },
  methods: {
    validate() {
      this.validated = true;
      if (this.question1 == undefined) {
        return false;
      }
      if (this.question2 == undefined) {
        return false;
      }
      if (
        this.question1 == true &&
        Store.getAnswer(this.section.getFilteredQuestions("")[1].key, 0) == ""
      ) {
        return false;
      }
      if (
        this.question2 == true &&
        Store.getAnswer(this.section.getFilteredQuestions("")[3].key, 0) == ""
      ) {
        return false;
      }
      return true;
    },
    change() {
      this.validated = false;
    },
    question1Updated(value:Boolean) {
      Store.setAnswer(this.section.getFilteredQuestions("")[0].key, value.toString());
      this.change();
    },
    question2Updated(value:Boolean) {
      Store.setAnswer(this.section.getFilteredQuestions("")[2].key, value.toString());
      this.change();
    }
    }
  },
);
