class QuestionLabelType {
    purchase: string = '';
    reMortgage: string = '';
}


export default class Question {
    key: string = ''
    private question: string|QuestionLabelType ='';
    filter: any = {}
    componentType: string;
    extras: any = {};

    constructor(key: string, question: string|QuestionLabelType, filter: any = {}, componentType: string = 'SingleLine', extras:any = {}) {
        this.key = key;
        this.question = question;
        this.componentType = componentType;
        this.filter = filter;
        this.extras = extras;
        
    }

    getQuestionLabel(filter:string): string {
        if(typeof this.question == 'string') {
            return this.question;
        }
        else {
            if(filter == 're-mortgage') {
                return this.question.reMortgage
            } else {
                return this.question.purchase;
            }
        }
    }

}
