
import { Options, Vue } from "vue-class-component";
import PageComponent from "../components/PageComponent.vue";
import { Form } from "../lib/structure";
import { Store } from "../lib/store";
import "bootstrap-icons/font/bootstrap-icons.css";
import ModalComponent from "../components/ModalComponent.vue";
import IntroComponent from "../components/IntroComponent.vue";
import SaveComponent from "../components/SaveComponent.vue";
import Network from "../lib/network";
import FinishComponent from "../components/FinishComponent.vue";
import DisclaimerComponent from "../components/DisclaimerComponent.vue";
import { useRoute } from 'vue-router'


@Options({
  name: "App",
  components: {
    PageComponent,
    ModalComponent,
    IntroComponent,
    SaveComponent,
    FinishComponent,
    DisclaimerComponent
  },
  methods: {
    nextPage() {
      if(!this.$refs.pageComponent.validate()) {
        return;
      }
      if (this.pageIndex == Form.length - 1) {
        this.disclaimer = true;
        //Network.finaliseForm(Store.getStore());
        return;
      }
      Store.nextPage();
      window.scrollTo({left: 0, top: 0, behavior: 'auto'});
    },
    sendCompletedForm() {
       Network.finaliseForm(Store.getStore())
          .then(() => {
            window.scrollTo({left: 0, top: 0, behavior: 'auto'});
            this.finished = true;
            this.disclaimer = false;
          })
          .catch(() => {alert('There was an error sending the form, if this problem persists please contact support')});


    },
    prevPage() {
      if (this.pageIndex == 0) {
        return;
      }
      Store.prevPage();
      window.scrollTo({left: 0, top: 0, behavior: 'auto'});
    },
    setPageIndex(index: number) {
      Store.setPageIndex(index);
    },
    currentPage(index: number) {
      return index == this.pageIndex ? "selected" : "";
    },
    hideIntroModal() {
      this.showIntroModal = false;
    },
    applicantTwoFocus() {
      console.log("handler");
      if (this.helpShown) {
        return;
      }
      this.showHelpModal = true;
      this.helpShown = true;
    },
  },
  data() {
    return {
      showSettingsModal: false,
      showIntroModal: false,
      showSaveModal: false,
      finished: false,
      helpShown: false,
      showHelpModal: false,
      disclaimer: false
    };
  },
  computed: {
    showPrevious() {
      return this.pageIndex > 0;
    },
    nextText() {
      return this.pageIndex == Form.length - 1 ? "Finish" : "Next";
    },
    isFinish() {
      return this.pageIndex == Form.length - 1;
    },
    pageIndex() {
      return Store.getPageIndex();
    },
    page() {
      return Form[Store.getPageIndex()];
    },
    form() {
      return Form;
    },
    emailAddress() {
      return Store.getAnswer("EMAIL");
    },
    isNew() {
      return Store.getCode() == -1;
    },
    applicationType: {
      get(): string {
        return Store.getApplicantCount() == 1 ? "sole" : "joint";
      },
      set(value: string) {
        Store.setApplicantCount(value == "sole" ? 1 : 2);
      },
    },
    mortgageType: {
      get(): string {
        return Store.getMortgageType();
      },
      set(value: string) {
        Store.setMortgageType(value);
      },
    },
  },
  mounted() {
    const route = useRoute();
    console.log(route);
    const code = route.query.code;
    if (code != null && code != undefined) {
      try {
        Network.getForm(parseInt(code.toString())).then((data) => {
          Store.setStore(data);
          Store.setCode(parseInt(code.toString()));
          Store.setPageIndex(0);
        });
      } catch {
        alert(
          "We couldn't find an application with that code, please contact support for more help."
        );
        this.showIntroModal = true;
      }
    } else {
      this.showIntroModal = true;
    }

    //add handler
    Store.blurHandler = () => {
      if (Store.getCode() > -1) {
        Network.updateForm(Store.getCode(), Store.getStore());
      }
    };
    Store.applicantTwoHandler = this.applicantTwoFocus;
  },
})
export default class FactFind extends Vue {}
