
import { defineComponent } from "@vue/runtime-core";
import Question from "../lib/question";
import { Store } from "../lib/store";
import Liability from '../lib/liability';

export default defineComponent({
  name: "LiabilitiesComponent",
  props: {
    question: {
      type: Question,
      required: true,
    },
  },
  methods: {
      updateVal(value:string, key:string, index:number) {
        let newData = this.data;
        newData[index][key] = value;
        this.data = newData;
      },
      addRow() {
          let newData = this.data;
          if(Array.isArray(newData)) {
            newData.push(new Liability());
          }
          this.data = newData;
      },
      deleteRow(index:number) {
          let newData = this.data as [];
          newData.splice(index, 1);
          this.data = newData;
      }
  },
  computed: {
    data: {
        get() {
            let data = Store.getAnswer(this.question.key, 0, [new Liability()]);
           
            return data;
        },
        set(val:[]) {
            console.log(val);
            Store.setAnswer(this.question.key, val);
        }
    },
  },
});
